import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BritechesterMap from '../../assets/img/maps/britechester/britechester.png';
import UniversityOfBritechester from '../../assets/img/maps/britechester/britechesteru.png';
import FoxburyInstitute from '../../assets/img/maps/britechester/foxbury.png';
import GibbsHill from '../../assets/img/maps/britechester/gibshill.png';

function Britechester(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Britechester</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on Oxford</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3 - Foxbury Institute | Gibbs Hill | University of Britchester</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Discover University</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">13</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Fletcher Family - 4.000 | Elderberry Family - 25.000 | Best of Friends Household - 5.000 | Pleasant Family - 20.000</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={BritechesterMap} alt="Britechester" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">University of Britechester</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">This university is the oldest in Britechester, founded by the famous Felix Psyded, who was well known for his enthusiasm for learning and humorously large books. As the years have passed the University of Britechester has established itself as an esteemed institute of classical education.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={UniversityOfBritechester} alt="Britchester Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={FoxburyInstitute} alt="Britchester Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Foxbury Institute</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Foxbury Institute may seem young compared to the historical town surrounding it, but it has quickly distinguished itself in academia. For the students who have worked hard to attend Foxbury Institute, it’s not just work, but the pursuit of personal passions that keeps this campus bustling.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Gibbs Hill</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Gibbs Hill is nestled in the center of Britechester right between the two universities. Foxbury Institute and the University of Britechester. It was the first center of activity in Britechester and its famous Laurel Library has stayed a key location for many students to study and learn. The Pepper's Pub has also become a common spot for students from all around to make friends regardless of their rivalries.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={GibbsHill} alt="Britchester Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
        <Footer />
        </div>
    );
};

export default Britechester;