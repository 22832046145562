import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import CopperdaleMap from '../../assets/img/maps/copperdale/copperdale.jpg';
import PlumbiteCove from '../../assets/img/maps/copperdale/plumbitecove.png';
import PrescottSquare from '../../assets/img/maps/copperdale/prescottsquare.png';
import RockridgeHeights from '../../assets/img/maps/copperdale/rockridge.png';


function Copperdale(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Copperdale</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on British Columbia</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3 - Rockridge Heights | Prescott Square | Plumbite Cove</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">High School Years</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">12</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Harjo Family - 22.000 | Prescott Family - 50.000 | Price Household - 28.000</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={CopperdaleMap} alt="Copperdale" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Plumbite Cove</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Settled on Lake LaSuli sits Plumbite Cove, a once-popular location for its mining of rare gems. After the mines shut down, the town turned this picturesque outlook into a waterfront fairground. With thrills, chills, and a breathtaking view, Plumbite Pier is the ideal spot for all ages to make some memories. Travel to Totter Park to see the majestic Teeter Rock before heading to the pier to snag a selfie with the Frank the Flying Womp Womp tribute. Whether settling down or just stopping by, Plumbite Cove is a must-see Copperdale gem.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={PlumbiteCove} alt="Copperdale Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={PrescottSquare} alt="Copperdale Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Prescott Square</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Once renowned for its booming mining industry, the town of Copperdale, quaint and historic, lay nestled on the banks of Lake LaSuli. Founder Jasper Prescott had foraged this land for its treasured crystals, hoping Copperdale would one day blossom into a prosperous community for families and travelers alike. And though the mine carts reached an eternal halt, the townspeople continued to flourish. With academics and adventures abound, Copperdale is now home to many families seeking a small-town life with big-city dreams for themselves and their teens. Together they’ve unearthed a gem far more precious than before – beloved high school memories.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Rockridge Heights</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Home to Copperdale High, Rockridge Heights is the town’s standard-bearer for academia across generations. It’s a beloved school ground for quintessential teen moments and college preparation. With classrooms full of learning, and an Auditorium full of Prom possibilities, Rockridge Heights is the perfect environment for every teen to discover who and what they want to be.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={RockridgeHeights} alt="Copperdale Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
        <Footer />
        </div>
    );
};

export default Copperdale;