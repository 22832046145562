import React from 'react';
import Mapa1 from '../assets/img/mapsf/fanart1.jpg';
import Mapa2 from '../assets/img/mapsf/fanart2.png';
import Mapa3 from '../assets/img/mapsf/fanart3.jpg';
import Mapa4 from '../assets/img/mapsf/fanart4.jpg';
import Mapa5 from '../assets/img/mapsf/fanart5.jpg';
import Mapa6 from '../assets/img/mapsf/fanart6.jpg';
import Mapa7 from '../assets/img/mapsf/fanart7.jpg';
import Mapa8 from '../assets/img/mapsf/fanart8.jpg';

function ImageGallery() {
    return (
      <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa1} alt="Fanart 1" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa2} alt="Fanart 2" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa3} alt="Fanart 3" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

             
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa4} alt="Fanart 4" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

                      
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa5} alt="Fanart 5" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

                 
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa6} alt="Fanart 6" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>

                
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa7} alt="Fanart 7" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>
            
               
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Mapa8} alt="Fanart 8" className="h-full w-full object-cover object-center" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Map Creator</h3>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ImageGallery;