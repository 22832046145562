import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


function Faq() {

      return (
        <div>
          <Navbar />
          <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">FAQ</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Some of your most common questions answered</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 ">
                  <dt className="text-sm font-medium text-gray-900">How do I submit my own fan-made map?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900">By clicking on the button on the upper-right corner and filling out the form with your name, social media tag (if you want to share it) and the file in png, jpg or gif format.</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left ">
                  <dt className="text-sm font-medium text-gray-900">Are there any guidelines or requirements for submitting a map?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900 ">Your designs must be yours and the file size can't be over 2GB. Other than that just be creative and try to be mindful of what others will see.</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left ">
                  <dt className="text-sm font-medium text-gray-900">Can I submit multiple maps?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900 ">Yes, you can submit as many as you want.</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left ">
                  <dt className="text-sm font-medium text-gray-500">How long does it take for my submitted map to appear on the website?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900 ">Every map is manually review so it may take up to 4 days before it is shared if approved.</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left ">
                  <dt className="text-sm font-medium text-gray-900">What if I want to remove my submitted map from the website?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900 s">Just reach out to us at simsmapscontact@gmail.com and we will remove it.</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left s">
                  <dt className="text-sm font-medium text-gray-900">Are there any copyright concerns or legal considerations related to the fan-made maps?</dt>
                  <br />
                  <dd className="mt-1 text-sm text-gray-900 ">All drawings displayed on this website are the property of their respective artists. They are shared here solely for the purpose of appreciation and promotion. The artists retain full ownership and rights to their work. If you believe your copyrighted work has been used without permission, kindly contact us for prompt resolution.</dd>
                </div>
              </dl>
            </div>
            <div class="mx-auto max-w-2xl pt-12 text-center sm:pt-40">
              <h1>If you have any more questions, email us at simsmapscontact@gmail.com </h1>   
            </div>
          </div>
        </div>
        <Footer />
        </div>
      );
    }
    
    export default Faq;