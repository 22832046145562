import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FoundryCove from '../../assets/img/maps/willowcreek/foundrycove.png';
import CourtyardLane from '../../assets/img/maps/willowcreek/courtyardlane.png';
import PendulaView from '../../assets/img/maps/willowcreek/pendulaview.png';
import SageEstates from '../../assets/img/maps/willowcreek/sageestates.png';
import CrawdadQuarter from '../../assets/img/maps/willowcreek/crawdadquarter.png';
import WillowCreekMap from '../../assets/img/maps/willowcreek/willowcreek.png';
import BlossomPark from '../../assets/img/maps/willowcreek/magnoliablossompark.png';

function WillowCreek(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Willow Creek</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on New Orleans</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">6 - Foundry Cove | Courtyard Lane | Pendula View | Sage Estates | Crawdad Quarter | Magnolia Park Blossom </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Base Game</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">21</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">1</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">BFF Household - 9.000 | Pancakes Family - 20.000 | Spencer-Kim-Lewis Family - 55.500 | Goth Family - 45.500</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={WillowCreekMap} alt="Willow Creek" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Foundry Cove</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Located in southern Willow Creek. Foundry Cove has lots of character (and its share of characters). Residents enjoy modest homes with a canal nearby for fishing and a charming active railroad.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={FoundryCove} alt="Willow Creek Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={CourtyardLane} alt="Willow Creek Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Courtyard Lane</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Courtyard Lane offers affordable homes for the upwardly mobile set. Classic styling mingles with larger shotguns and more expansive homes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Pendula View</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">This historical section of Willow Creek, the Garden Community boasts large mansions and the cache that comes with living in them.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={PendulaView} alt="Willow Creek Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={SageEstates} alt="Willow Creek Zoom 4"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Sage Estates</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Willow Creek's most desirable area. Sage Estates features large, sprawling estates, lush landscaping and the best high society has to offer. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Crawdad Quarter</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Crawdad Quarter is the heart of Willow Creek. All of the towns liveliness, from parks to nightlife, can be found in this neighborhood.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={CrawdadQuarter} alt="Willow Creek Zoom 5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={BlossomPark} alt="Willow Creek Zoom 6"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Magnolia Blossom Park </h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Magnolia Blossom Park is a historic park where Willow Creek inhabitants from all walks of life can come together to enjoy nature in a lush, green environment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer />
        </div>
    );
};

export default WillowCreek;