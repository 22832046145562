import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Mapa1 from '../assets/img/mapsf/fanart1.jpg';
import Mapa2 from '../assets/img/mapsf/fanart2.png';
import Mapa3 from '../assets/img/mapsf/fanart3.jpg';
import Mapa4 from '../assets/img/mapsf/fanart4.jpg';
import Mapa5 from '../assets/img/mapsf/fanart5.jpg';
import Mapa6 from '../assets/img/mapsf/fanart6.jpg';
import Mapa7 from '../assets/img/mapsf/fanart7.jpg';
import Mapa8 from '../assets/img/mapsf/fanart8.jpg';

const ImageCarousel = () => {
  return (
    <Carousel className='mx-auto max-w-3xl pt-12 pb-6'>
      <div>
        <img src={Mapa1} alt="Image 1" />
      </div>
      <div>
        <img src={Mapa2} alt="Image 2" />
      </div>
      <div>
        <img src={Mapa3} alt="Image 3" />
      </div>
      <div>
        <img src={Mapa4} alt="Image 3" />
      </div>
      <div>
        <img src={Mapa5} alt="Image 3" />
      </div>
      <div>
        <img src={Mapa6} alt="Image 3" />
      </div>
      <div>
        <img src={Mapa7} alt="Image 3" />
      </div>
      <div>
        <img src={Mapa8} alt="Image 3" />
      </div>
    </Carousel>
  );
};

export default ImageCarousel;
