import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import NewcrestMap from '../../assets/img/maps/newcrest/newcrest.jpg';
import Bridgeview from '../../assets/img/maps/newcrest/bridgeview.png';
import LlamaLagoon from '../../assets/img/maps/newcrest/llamalagoon.png';
import RidgelineDrive from '../../assets/img/maps/newcrest/ridgeline.png';


function Newcrest(){
    return(
        <div>
            <Navbar />
    
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
                    <div className="px-4 sm:px-6">
                        <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Newcrest</h3>
                        <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on North Carolina</p>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Type</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                            </div>
                            <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"> 3 - Bridgeview | Llama Lagoon | Ridgeline Drive </dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Pack</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Base Game</dd>
                            </div>
                            <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">15</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                            </div>
                            <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Households</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <img src={NewcrestMap} alt="Newcrest" className="h-full w-full object-cover object-center group-hover:opacity-75" />
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                    <div className="relative isolate bg-white">
                        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                            <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Bridgeview</h2>
                                    <p className="mt-6 text-justify text-lg leading-8 text-gray-600">This family-friendly neighborhood has a lovely picnic area and a striking city skyline backdrop.</p>
                                </div>
                            </div>
                            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                    <img src={Bridgeview} alt="Newcrest Zoom 1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                    <div className="relative isolate bg-white">
                        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                            <div className="mx-auto max-w-xl ">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                    <img src={LlamaLagoon} alt="Newcrest Zoom 2"/>
                                </div>
                            </div>
                            <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Llama Lagoon</h2>
                                    <p className="mt-6 text-lg text-justify leading-8 text-gray-600">With lakeside walkways and public playground equipment, this neighborhood's just the right amount of cosmopolitan.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                    <div className="relative isolate bg-white">
                        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                            <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                                <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Ridgeline Drive</h2>
                                    <p className="mt-6 text-justify text-lg leading-8 text-gray-600">This busy strip of real estate lining the streets of the Newcrest suburbs boasts ample hiking trails.</p>
                                </div>
                            </div>
                            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                    <img src={RidgelineDrive} alt="Newcrest Zoom 3"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    

    );
};

export default Newcrest;