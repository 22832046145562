import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import EvergreenHarborMap from '../../assets/img/maps/evergreenharbor/evergreenharbor.jpg';
import ConiferStation from '../../assets/img/maps/evergreenharbor/coniferstation.png';
import GrimsQuarry from '../../assets/img/maps/evergreenharbor/grimsquarry.png';
import PortPromise from '../../assets/img/maps/evergreenharbor/portpromise.png';

function EvergreenHarbor(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Evergreen Harbor</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on Oregon</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3 - Grims Quarry | Port Promise | Conifer Station</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Ecolifestyle</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">13</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Greenburg Family - 15.000 | Harris Family - 20.000 | Sterling Rico Family - 25.000 | Tinker Family - 30.000</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={EvergreenHarborMap} alt="Evergreen Harbor" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Grims Quarry</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">When prospector Arnold Grims founded his famous rock quarry in Evergreen Harbor, he could hardly have predicted the little suburb that would spring up around it decades later. Today, a cozy set of homes, apartments, and spaces for community use sits in the shadow of the walls of striking white stone.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={GrimsQuarry} alt="Evergreen Harbor Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={PortPromise} alt="Evergreen Harbor Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Port Promise</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">What was once a bustling trade port now holds a number of homes and businesses that pay homage to their industrial roots and aim to, as the local expression goes, "Keep Evergreen Kooky!" While the actual port may no longer be running, the promise of something better remains.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Conifer Station</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">What happens when the trains stop running? Downtown Evergreen, affectionately known to locals as Conifer Station, may be a bit quieter nowadays, but residents will tell you that the defunct train station and unsightly storage tanks hide a hard-working community eager to improve their little corner of the world.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={ConiferStation} alt="Evergreen Harbor Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer />
        </div>
    );
};

export default EvergreenHarbor;