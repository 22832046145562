
import React from "react";

function FooterHome() {
    return (
        <footer className="bg-gray-900 pt-4">
          <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:order-1 md:mt-0">
              <p className="text-center text-xs leading-5 text-white">&copy; 2023 Sims Maps, Inc. All rights reserved.</p>
            </div>
          </div>
        </footer>
    );
  }
  
  export default FooterHome;
  