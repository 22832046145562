import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDmBQLfdmdFWj_8F4Y4hgMS1FWSdEgx05o",
  authDomain: "sims-maps.firebaseapp.com",
  projectId: "sims-maps",
  storageBucket: "sims-maps.appspot.com",
  messagingSenderId: "129768304390",
  appId: "1:129768304390:web:1e04ce090b3a3df6663887",
  measurementId: "G-LSR462TN6K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);