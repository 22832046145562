import './App.css';
import './dist/output.css';
import Home from './pages/Home';
import Maps from './pages/Maps';
import Upload from './pages/Upload';
import Gallery from './pages/Gallery';
import About from './pages/About';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WillowCreek from './pages/worlds/WillowCreek';
import OasisSprings from './pages/worlds/OasisSprings';
import Newcrest from './pages/worlds/Newcrest';
import MagnoliaPromenade from './pages/worlds/MagnoliaPromenade';
import Windenburg from './pages/worlds/Windenburg';
import SanMyshuno from './pages/worlds/SanMyshuno';
import ForgottenHollow from './pages/worlds/ForgottenHollow';
import BrindletonBay from './pages/worlds/BrindletonBay';
import DelSolValley from './pages/worlds/DelSolValley';
import Strangerville from './pages/worlds/Strangerville';
import Sulani from './pages/worlds/Sulani';
import Glimmerbrook from './pages/worlds/Glimmerbrook';
import Britechester from './pages/worlds/Britechester';
import EvergreenHarbor from './pages/worlds/EvergreenHarbor';
import MtKomorebi from './pages/worlds/MtKomorebi';
import Batuu from './pages/worlds/Batuu';
import GraniteFalls from './pages/worlds/GraniteFalls';
import Selvadorada from './pages/worlds/Selvadorada';
import HenfordOnBagley from './pages/worlds/HenfordOnBagley';
import Tartosa from './pages/worlds/Tartosa';
import MoonwoodMill from './pages/worlds/MoonwoodMill';
import Copperdale from './pages/worlds/Copperdale';
import SanSequoia from './pages/worlds/SanSequoia';
import Faq from './pages/Faq';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/"  element={<Home/>} />
          <Route path="/maps" element={<Maps/>} />
          <Route path="/gallery" element ={<Gallery/>} />
          <Route path="/upload" element ={<Upload/>} />
          <Route path="/about" element ={<About/>} />
          <Route path="/faq" element ={<Faq/>} />
          <Route path="/willowcreek" element={<WillowCreek />}/>
          <Route path="/oasissprings" element={<OasisSprings />}/>
          <Route path="/newcrest" element={<Newcrest />}/>
          <Route path="/magnoliapromenade" element={<MagnoliaPromenade />}/>
          <Route path="/windenburg" element={<Windenburg />}/>
          <Route path="/sanmyshuno" element={<SanMyshuno />}/>
          <Route path="/forgottenhollow" element={<ForgottenHollow />}/>
          <Route path="/brindletonbay" element={<BrindletonBay />}/>
          <Route path="/delsolvalley" element={<DelSolValley />}/>
          <Route path="/strangerville" element={<Strangerville />}/>
          <Route path="/sulani" element={<Sulani />}/>
          <Route path="/glimmerbrook" element={<Glimmerbrook />}/>
          <Route path="/britechester" element={<Britechester />}/>
          <Route path="/evergreenharbor" element={<EvergreenHarbor />}/>
          <Route path="/mtkomorebi" element={<MtKomorebi />}/>
          <Route path="/batuu" element={<Batuu />}/>
          <Route path="/granitefalls" element={<GraniteFalls />}/>
          <Route path="/selvadorada" element={<Selvadorada />}/>
          <Route path="/henfordonbagley" element={<HenfordOnBagley />}/>
          <Route path="/tartosa" element={<Tartosa />}/>
          <Route path="/moonwoodmill" element={<MoonwoodMill />}/>
          <Route path="/copperdale" element={<Copperdale />}/>
          <Route path="/sansequoia" element={<SanSequoia />}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
