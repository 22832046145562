import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import TartosaMap from '../../assets/img/maps/tartosa/tartosa.jpg';
import PortoLuminoso from '../../assets/img/maps/tartosa/portoluminoso.png';
import TerraAmorosa from '../../assets/img/maps/tartosa/terraamorosa.png';


function Tartosa(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Tartosa</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on Italy</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">2 - Terra Amorosa | Porto Luminoso</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">My Wedding Stories</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">9</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Laurent Family - 20.000 | Markovic Family - 20.000 | Soto Family - 20.000 | Jaleel Household - 20.000</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={TartosaMap} alt="Tartosa" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Porto Luminoso</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Historians say Porto Luminoso’s name originated when pirates arrived at shore, attracted by the glowing tides, the promise of trade, and the invention of the fruit tart. Overtime, the village flourished, and while still famous for its fruit tarts, Porto Luminoso’s real claim to fame is its self-proclaimed-title as the wedding industry’s premiere destination for romance and matrimonial services.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={PortoLuminoso} alt="Tartosa Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={TerraAmorosa} alt="Tartosa Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Terra Amorosa</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">A short trip up the coastline from Porto Luminosos is the loving land known as Terra Amorosa to the locals. Whether fishing off the boardwalk, swimming at the bottom of the secluded Hideaway Falls, or hiking up the hillside to visit the area’s famous Lover’s Tree, any Sim is bound to fall madly in love with the various charming sights and historic landmarks this lover’s paradise has to offer.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer />
        </div>
    );
};

export default Tartosa;