import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function About() {

      return (
        <div>
          <Navbar />
          <div class="px-6 pt-14 lg:px-8">
            <div class="mx-auto max-w-2xl pt-20 text-center sm:pt-40">
                <h2 class="text-base font-bold tracking-tight text-gray-900 sm:text-3xl">What is Sims Maps?</h2>
                <p class="mt-6 text-base leading-8 text-gray-900">It's your go-to destination for all things Sims 4 world maps! We've compiled all the information you need about the different world maps in one convenient place. Plus, you can share your own fan art maps with our community. Discover the world of Sims 4 with us!</p>
            </div>
            <div class="mx-auto max-w-2xl pt-12 text-center sm:pt-40">
                <h2 class="text-base font-bold tracking-tight text-gray-900 sm:text-3xl">FAQ</h2>
                <p class="mt-6 text-base leading-8 text-gray-900">Do you have any questions? </p>
           
                <div className="flex justify-center pt-2">
                <Link to="/faq">
                  <button type="button" className="rounded-md buttonnew px-3 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Check the FAQ!
                  </button>
                </Link>
                </div>
            </div>
            <div class="mx-auto max-w-2xl pt-12 text-center sm:pt-40">
                <h2 class="text-base font-bold tracking-tight text-gray-900 sm:text-3xl">Contact us!</h2>
                <p class="mt-6 text-base leading-8 text-gray-900">Our email is simsmapscontact@gmail.com</p>
            </div>
            <div class="mx-auto max-w-2xl pt-12 text-center sm:pt-40">
                <p class="mt-6 text-xs leading-8 text-gray-900">Ownership Disclaimer: All drawings displayed on this website are the property of their respective artists. They are shared here solely for the purpose of appreciation and promotion. The artists retain full ownership and rights to their work. If you believe your copyrighted work has been used without permission, kindly contact us for prompt resolution.</p>
            </div>
          </div>
        <Footer />
        </div>
      );
    }
    
    export default About;