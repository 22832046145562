import React from "react";
import { Link } from "react-router-dom";
import Plumbobmini from '../assets/img/plumbobs/plumbobhappymini.png';
import Logo8 from '../assets/img/mapslogos/8.png';

function Navbar() {
    return (
        <div>
            <div className="bg-gray-900">
                <nav className="bg-white">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex ">
                                <div className="flex flex-shrink-0 items-center">
                                     <img className="block h-8 w-auto " src={Plumbobmini} alt="Plumbob" />
                                </div>
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    <Link to="/" className="text-gray-900 hover:bg-gray-300 hover:text-gray-500 rounded-md px-3 py-2 text-base font-medium" aria-current="page">Home</Link>
                                    <Link to="/maps" className="text-gray-900 hover:bg-gray-300 hover:text-gray-500 rounded-md px-3 py-2 text-base font-medium">Maps</Link>
                                    <Link to="/gallery" className="text-gray-900 hover:bg-gray-300 hover:text-gray-500 rounded-md px-3 py-2 text-base font-medium">Gallery</Link>
                                    <Link to="/about" className="text-gray-900 hover:bg-gray-300 hover:text-gray-500 rounded-md px-3 py-2 text-base font-medium">About</Link>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img className="block h-20 pt-4 w-auto " src={Logo8} alt="Sims Maps Logo" />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link to="/upload">
                                        <button onclick="window.location.href='./Upload.html';" type="button" className="relative inline-flex items-center gap-x-1.5 rounded-md buttonnew px-3 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                            <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                                            </svg>
                                            Upload Your Map
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
  };
  
  export default Navbar;