import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import SanSequoiaMap from '../../assets/img/maps/sansequoia/sansequoia.png';
import AnchorpointWharf from '../../assets/img/maps/sansequoia/anchorpoint.png';
import GilbertGardens from '../../assets/img/maps/sansequoia/gilbertgardens.png';
import HopewellHills from '../../assets/img/maps/sansequoia/hopewellhills.png';

function SanSequoia(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">San Sequoia</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on San Francisco</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3 - Anchorpoint Wharf | Gilbert Gardens | Hopewell Hills</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Growing Together</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">12</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">The Michaelson Family - 20.000 | The Robles Family - 20.000 | The Robles-Ruano Family - 20.000 | The Luna-Sullivan Family - 20.000 | The Kyleson Family - 20.000</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={SanSequoiaMap} alt="San Sequoia" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Anchorpoint Wharf</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Once the center of a bustling fishing and canning industry, Anchorpoint Wharf is known now as a vibrant area downtown featuring a movie theater, parklets, and more. There’s plenty to keep all ages entertained.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={AnchorpointWharf} alt="San SequoiaZoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={GilbertGardens} alt="San Sequoia Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Gilbert Gardens</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">Gilbert Gardens’ gorgeous park was once owned by Gilbert Gilberts, a land developer who helped establish the area. Now, it’s the perfect locale for family trips to the gardens. Where, amongst other activities, Sims can take a walk on one of the three scenic lake walking paths or visit the community’s Splash Pad play area. Whalebert is hoping to see you soon!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Hopewell Hills</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Hopewell Hills is a cozy spot away from the hustle and bustle of town. The perfect place for anyone who wants, well, space! If the suburbs are your idea of paradise, you’ve found it here in this quiet neighborhood.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={HopewellHills} alt="San Sequoia Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
        <Footer />
        </div>
    );
};

export default SanSequoia;