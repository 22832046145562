import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import HenfordOnBagleyMap from '../../assets/img/maps/henfordonbagley/henfordonbagley.jpg';
import TheBramblewood from '../../assets/img/maps/henfordonbagley/bramblewood.png';
import Finchwick from '../../assets/img/maps/henfordonbagley/finchwick.png';
import OldNewHenford from '../../assets/img/maps/henfordonbagley/oldnewhenford.png';


function HenfordOnBagley(){
    return(
        <div>
            <Navbar />
            <div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
            <div className="px-4 sm:px-6">
                <h3 className="text-3xl text-left font-semibold leading-6 text-gray-900">Henford-on-Bagley</h3>
                <p className="mt-1 max-w-2xl text-left text-base text-gray-500">Neighborhood inspired on England</p>
              </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white text-left px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Living World</dd>
                </div>
                <div className="bg-white px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Neighborhoods</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3 - Finchwick | Old New Henford | The Bramblewood</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Pack</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Cottage Living</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">12</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Number of secret lots</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">0</dd>
                </div>
                <div className="bg-white-50 px-4 py-5 text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Households</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">A New Star Household - 20.000 | Moody & McMillan Family - 22.000 | Scott Family - 22.000 | Watson Family - 28.000 | Chopra Familly - 22.000 | Crumplebottom Family - Unplayable</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
    <div>
        <img src={HenfordOnBagleyMap} alt="Henford-on-Bagley" class="h-full w-full object-cover object-center group-hover:opacity-75" />
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Finchwick</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">Nestled along the River Bagley, the old mill village of Finchwick prides itself on its bustling and close-knit community. Its historic square features a pub and quaint stalls amid a flowering garden, perfect for a little window shopping. Tourists and locals alike flock to the weekly village fair, where Finchwick’s Mayor makes a special appearance to judge entries and gossip about the latest happenings.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={Finchwick} alt="Henford-on-Bagley Zoom 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="mx-auto max-w-xl ">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-2 pr-10 sm:grid-cols-1">
                                <img src={OldNewHenford} alt="Henford-on-Bagley Zoom 2"/>
                            </div>
                        </div>
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Old New Henford</h2>
                                <p className="mt-6 text-lg text-justify leading-8 text-gray-600">An area that boasts rolling hills, lush estates, and a small community garden, Old New Henford is perhaps best known for its historical naming woes. Initially known as Little Henford, the neighborhood renamed itself New Henford in an attempt to escape its diminutive origins. However, in neglecting to inform national mapmakers, they ceased to officially exist for a two-week period. When told that to be reinstated would require yet another name change, residents chose Old New Henford, which beat out the other choice – New New Henford – by a single vote.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
                <div className="relative isolate bg-white">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                        <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
                            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">The Bramblewood</h2>
                                <p className="mt-6 text-justify text-lg leading-8 text-gray-600">The natural beauty of these woods have long provided a picturesque place for many wild birds, rabbits, and foxes to call home. Whether secreting a small surprise under Sophie the Snail, bringing an easel to paint the serene Cordelia Falls, foraging for wild mushrooms, or finding romance among the ruins of the Isle of Volpe, any Sim would be transformed by a day in the Bramblewood.</p>
                            </div>
                        </div>
          
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 pt-20 pl-2 sm:grid-cols-1">
                                <img src={TheBramblewood} alt="Henford-on-Bagley Zoom 3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer />
        </div>
    );
};

export default HenfordOnBagley;