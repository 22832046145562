import React from 'react';
import { Link } from 'react-router-dom';
import NavbarHome from '../components/NavbarHome';
import FooterHome from '../components/FooterHome';
import PlumbobMicro from '../assets/img/plumbobs/plumbobhappymicro.png';
import LogoOcho from '../assets/img/mapslogos/8.4.png';
import CollageMaps from '../assets/img/other/collage4.png';
import UploadForm from '../assets/img/other/uploadform8.png';
import CollageUploads from '../assets/img/other/collage5.png';

function Home() {
    return (
      <div>
        <NavbarHome />
        <div className="relative isolate overflow-hidden bg-gray-900 pb-16  sm:pb-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-10 ">
              <div className="py-2">
                <img src={PlumbobMicro} alt="Sims 4 Maps." className="mx-auto" />
              </div>
              <div >
                <img src={LogoOcho} alt="Sims Maps" className="mx-auto"/>
              </div>
              <div className="text-center">
                <p className="mt-6 text-lg leading-8 text-gray-300">Welcome to our website, your go-to destination for all things Sims 4 world maps! We've compiled all the information you need about the different world maps in one convenient place. Plus, you can share your own fan art maps with our community. Discover the world of Sims 4 with us!</p>
              </div>
              <div className="flex justify-center pt-2">
                <Link to="/maps">
                  <button  type="button" className="rounded-md buttonnew px-3 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Check the Maps!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>


        <div className="relative isolate bg-gray-900">
          <div className="mx-auto grid  max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <img src={CollageMaps} alt="Collage Maps"/>
              </div>
            </div>
              
            <div className="mx-auto max-w-xl mr-4 ml-4 pt-20 lg:max-w-lg">
              <div>
                <h1 className="text-white text-conthead"> Ready to explore the Sims 4 world like a pro? </h1>
                <br />
                <p className="text-white text-cont">Look no further than our detailed maps! Unlock hidden secrets and elevate your gameplay today.</p>
                <br />
                <Link to="/maps">
                  <button  type="button" className="rounded-md buttonnew px-3 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Check the Maps!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>


        <div className="relative isolate bg-gray-900">
          <div className="mx-auto grid  max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="mx-auto max-w-xl mr-4 ml-3 pt-20 lg:max-w-lg">
              <div>
                <h1 className="text-white text-conthead"> Calling all talented artists and Sims 4 enthusiasts!</h1>
                <br />
                <p className="text-white  text-subcont">We invite you to showcase your creativity and share your fantastic fan art drawings of Sims 4 maps with the world. Join our vibrant community and have your masterpieces featured in our exclusive gallery. Don't miss this opportunity to inspire fellow Simmers and become part of our immersive Sims 4 art collection. Submit your drawings today and let your talent shine!</p>
                <br />
                <Link to="/upload">
                  <button  type="button" className="rounded-full buttonnew p-2 text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
            <div className="relative px-6 pb-20 pt-10 sm:pt-20 lg:static lg:px-8 lg:py-10">
              <div className="mx-auto max-w-xl ">
                <img src={UploadForm} alt="Upload Form"/>
              </div>
            </div>
          </div>
        </div>

        <div className="relative isolate bg-gray-900">
          <div className="mx-auto grid  max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <img src={CollageUploads} alt="Collage from Gallery"/>
              </div>
            </div>
              
            <div className="mx-auto max-w-xl mr-3 ml-4 pt-20 lg:max-w-lg">
              <div>
                <h1 className="text-white text-conthead"> Explore the wonders beyond the ordinary</h1>
                <br />
                <p className="text-white text-subcont">Delve into maps crafted by passionate fans. Unveil hidden realms and undiscovered treasures, embark on a journey through the Gallery's vast expanse.</p>
                <br />
                <Link to="/gallery">
                  <button  type="button" className="rounded-md buttonnew px-3 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Check out the Fan Gallery!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterHome />
      </div>
    );
  };
  
  export default Home;