import React from 'react';
import { Link } from 'react-router-dom';
import Batuu from '../assets/img/maps/batuu/batuu.png';
import BrindletonBay from '../assets/img/maps/brindletonbay/brindletonbay.jpg';
import Britechester from '../assets/img/maps/britechester/britechester.png';
import Copperdale from '../assets/img/maps/copperdale/copperdale.jpg';
import DelSolValley from '../assets/img/maps/delsolvalley/delsolvalley.jpg';
import EvergreenHarbor from '../assets/img/maps/evergreenharbor/evergreenharbor.jpg';
import ForgottenHollow from '../assets/img/maps/forgottenhollow/forgottenhollow.png';
import Glimmerbrook from '../assets/img/maps/glimmerbrook/glimmerbrook.jpg';
import GraniteFalls from '../assets/img/maps/granitefalls/granitefalls.png';
import HenfordOnBagley from '../assets/img/maps/henfordonbagley/henfordonbagley.jpg';
import MagnoliaPromenade from '../assets/img/maps/magnoliapromenade/magnoliapromenade.png';
import MoonwoodMill from '../assets/img/maps/moonwoodmill/moonwoodmill.jpg';
import MtKomorebi from '../assets/img/maps/mtkomorebi/mtkomorebi.jpg';
import Newcrest from '../assets/img/maps/newcrest/newcrest.jpg';
import OasisSprings from '../assets/img/maps/oasissprings/oasissprings.png';
import SanMyshuno from '../assets/img/maps/sanmyshuno/sanmyshuno.png';
import SanSequoia from '../assets/img/maps/sansequoia/sansequoia.png';
import Selvadorada from '../assets/img/maps/selvadorada/selvadorada.png';
import Strangerville from '../assets/img/maps/strangerville/strangerville.jpg';
import Sulani from '../assets/img/maps/sulani/sulani.png';
import Tartosa from '../assets/img/maps/tartosa/tartosa.jpg';
import WillowCreek from '../assets/img/maps/willowcreek/willowcreek.png';
import Windenburg from '../assets/img/maps/windenburg/windenburg.png';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


function Maps() {


    return (
      <div>
        <Navbar />
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              
              <Link to="/willowcreek" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={WillowCreek} alt="Willow Creek." className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Willow Creek</h3>
              </Link>
  
              <Link to="/oasissprings" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={OasisSprings} alt="Oasis Springs" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Oasis Springs</h3>
              </Link>

              <Link to="/newcrest" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Newcrest} alt="Newcrest" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Newcrest</h3>
              </Link>

              <Link to="/magnoliapromenade" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={MagnoliaPromenade} alt="Magonlia Promenade" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Magnolia Promenade</h3>
              </Link>
        
              <Link to="/windenburg" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Windenburg} alt="Windenburg" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Windenburg</h3>
              </Link>

              <Link to="/sanmyshuno" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={SanMyshuno} alt="San Myshuno" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">San Myshuno</h3>
              </Link>
              
              <Link to="/forgottenhollow" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={ForgottenHollow} alt="Forgotten Hollow" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Forgotten Hollow</h3>
              </Link>

              <Link to="/brindletonbay" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={BrindletonBay} alt="Brindleton Bay" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Brindleton Bay</h3>
              </Link> 
          
              <Link to="/delsolvalley" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={DelSolValley} alt="Del Sol Valley" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Del Sol Valley</h3>
              </Link> 
          
              <Link to="/strangerville" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Strangerville} alt="Strangerville" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Strangerville</h3>
              </Link> 
          
              <Link to="/sulani" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Sulani} alt="Sulani" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Sulani</h3>
              </Link> 
          
              <Link to="/glimmerbrook" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Glimmerbrook} alt="Glimmerbrook" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Glimmerbrook</h3>
              </Link> 
          
              <Link to="/britechester" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Britechester} alt="Britechester" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Britechester</h3>
              </Link>
          
              <Link to="/evergreenharbor" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={EvergreenHarbor} alt="Evergreen Harbor" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Evergreen Harbor</h3>
              </Link> 
          
              <Link to="/mtkomorebi" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={MtKomorebi} alt="Mt. Komorebi" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Mt. Komorebi</h3>
              </Link> 
          
              <Link to="/batuu" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Batuu} alt="Batuu" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Batuu</h3>
              </Link> 
          
              <Link to="/granitefalls" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={GraniteFalls} alt="Granite Falls" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Granite Falls</h3>
              </Link> 
          
              <Link to="/selvadorada" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Selvadorada} alt="Selvadorada" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Selvadorada</h3>
              </Link> 
          
              <Link to="/henfordonbagley" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={HenfordOnBagley} alt="Henford-on-Bagley" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Henford-on-Bagley</h3>
              </Link> 
          
              <Link to="/tartosa" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Tartosa} alt="Tartosa" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Tartosa</h3>
              </Link> 
          
              <Link to="/moonwoodmill" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={MoonwoodMill} alt="Moonwood Mill" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Moonwood Mill</h3>
              </Link>

              <Link to="/copperdale" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={Copperdale} alt="Copperdale" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">Copperdale</h3>
              </Link>
          
              <Link to="/sansequoia" className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img src={SanSequoia} alt="San Sequoia" className="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">San Sequoia</h3>
              </Link>
                
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default Maps;