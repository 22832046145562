import React, { useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ImageCarousel from '../components/ImageCarousel';
import Image1 from '../assets/img/mapsf/fanart1.jpg';
import Image2 from '../assets/img/mapsf/fanart2.png';
import Image3 from '../assets/img/mapsf/fanart3.jpg';
import Image4 from '../assets/img/mapsf/fanart4.jpg';
import Image5 from '../assets/img/mapsf/fanart5.jpg';
import Image6 from '../assets/img/mapsf/fanart6.jpg';
import Image7 from '../assets/img/mapsf/fanart7.jpg';
import Image8 from '../assets/img/mapsf/fanart8.jpg';
import ImageGallery from '../components/ImageGallery';

function Gallery() {

  const [searchQuery, setSearchQuery] = useState('');
  const [images, setImages] = useState([
    {
      id: 1,
      name: 'Image 1',
      src: Image1,
      tags: ['uno', 'Sunrise Hills'],
    },
    {
      id: 2,
      name: 'Image 2',
      src: Image2,
      tags: ['dos', 'Mauna Llama Llama'],
    },
	 {
      id: 3,
      name: 'Image 3',
      src: Image3,
      tags: ['tres', 'Avalon'],
    },
	 {
      id: 4,
      name: 'Image 4',
      src: Image4,
      tags: ['cuatro', 'Unknown'],
    },
	 {
      id: 5,
      name: 'Image 5',
      src: Image5,
      tags: ['cinco', 'Diamond Crest'],
    },
	 {
      id: 6,
      name: 'Image 6',
      src: Image6,
      tags: ['seis', 'Green Haven'],
    },
    {
      id: 7,
      name: 'Image 7',
      src: Image7,
      tags: ['siete', 'Arcadia'],
    },
    {
      id: 8,
      name: 'Image 8',
      src: Image8,
      tags: ['ocho', 'Bridgeport'],
    }
    
  ]); 

  const [selectedImage, setSelectedImage] = useState(null); 

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = () => {
   
    const foundImage = images.find((image) =>
      image.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setSelectedImage(foundImage); 
    setSearchQuery('');
  };



    return (
      <div>
        <Navbar />
      
      
        <div className='pt-12'>
          <div className='pt-10 pb-6 flex flex items-center justify-center gap-4 gap-4'>
            <input type="text" className='search-input flex-grow-1' value={searchQuery} onChange={handleInputChange} />
            <button  type="button" onClick={handleButtonClick} className="rounded-md buttonnew px-6 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
              Search
            </button>
          </div>
          
            {selectedImage && (
            <img src={selectedImage.src} alt={selectedImage.name} key={selectedImage.id} />
      )}
      
    </div>
  
        <ImageCarousel />
        <ImageGallery />
        <Footer />
      </div>
    );
  }
  
  export default Gallery;