import React, { useState } from 'react';
import Fanart from '../assets/img/mapsf/fanart3.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../config/firebase";


  function Upload() {

    const [name, setName] = useState('');
    const [tag, setTag] = useState('');

    const [fileUpload, setFileUpload] = useState(null);
    const [showCheck, setShowCheck] = useState(false); 

    const resetForm = () => {
      setName('');
      setTag('');
      setFileUpload(null);
    };

    const uploadFile = async () => {
      if (!fileUpload) return;
      const filesFolderRef = ref(storage, `projectFiles/${name}_${tag}_${fileUpload.name}`);
      try {
        await uploadBytes(filesFolderRef, fileUpload);
        setShowCheck(true); 
        resetForm();
      } catch (err) {
        console.error(err);
      }
    };


      return (
        <div>
          <Navbar />
          <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-16 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-10">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-3xl text-left font-bold tracking-tight text-gray-900">Upload Your Map</h2>
                <p className="mt-6 text-left text-lg leading-8 text-gray-600">Share your Sims 4 fan art maps on our website and inspire others to get creative! Join our community now.</p>
                <br />
                <img src={Fanart} alt="Fanart Example"/>
              </div>
            </div>
            
            <div  className="px-6 pb-16 pt-20 sm:pb-16 lg:px-8 lg:py-10">
              <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label for="first-name" className="block text-sm text-left font-semibold leading-6 text-gray-900">Name</label>
                    <div className="mt-2.5">
                      <input type="text" value={name} name="first-name" id="first-name"  onChange={(e) => setName(e.target.value)} autocomplete="name" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label for="name" className="block text-sm text-left font-semibold leading-6 text-gray-900">@ On social media</label>
                    <div className="mt-2.5">
                      <input type="name" name="tag" value={tag} onChange={(e) => setTag(e.target.value)} id="tag" autocomplete="tag" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                  </div>
                </div>
                <div className="col-span-full pt-6">
                  <label for="cover-photo" className="block text-left text-sm font-medium leading-6 text-gray-900">Your Map</label>
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                      </svg>
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                          <span>{fileUpload ? fileUpload.name : 'Upload your file'}</span>
                          <input id="file-upload" name="file-upload" type="file" accept="image/*" onChange={(e) => setFileUpload(e.target.files[0])} className="sr-only" />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 2GB</p>
                    </div>
                  </div>
                </div>

                {showCheck ? 
                
              <div id="check" class="rounded-md bg-green-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">Successfully uploaded</p>
                  </div>
                </div>
              </div>
                
              : null}
              
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button  onClick={uploadFile} className=" buttonnew rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:buttonnew focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                
              </div>
            </div>
          </div>
          
        </div>
        <Footer />
        </div>
      );
    }
    
    export default Upload;